function initPage() {
	$(".form-select").selectmenu();

	$(".app-login-password-toggle").on('click', function() {
		$(this).toggleClass('active');
		const input = $('.app-login-input-password');
		input.attr('type', input.attr('type') === 'password' ? 'text' : 'password');
	});

	const forms = document.querySelectorAll('.needs-validation')
	Array.prototype.slice.call(forms)
	.forEach(function (form) {
		form.addEventListener('submit', function (event) {
			if (!form.checkValidity()) {
				event.preventDefault()
				event.stopPropagation()
			}

			form.classList.add('was-validated')
		}, false)
	});

}
